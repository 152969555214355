<template>
  <div>
    <div class="login-form-wrap">
      <div class="marquee">
        <el-carousel height="600px">
          <el-carousel-item v-for="(item, index) in marquee" :key="index">
            <el-image
              :src="item"
              style="width: 100%;height: 100%;"
              fit="cover"
            ></el-image>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="container flex flex-sb " style="z-index: 99;">
        <div></div>
        <div  class="login-form bg-white" style="border: 1px solid #ccc">
          <el-tabs :stretch="true" v-model="loginType">
            <el-tab-pane label="账号密码登录" name="first" class="no-scroll" >
              <el-form :model="accountForm" ref="accountForm"
                       autocomplete="off" :rules="accountFormRules">
                <el-form-item>
                  <el-select style="width: 100%;" v-model="accountForm.usertype" placeholder="账号类型">
                    <el-option label="管理人员" :value="0"></el-option>
                    <el-option label="客服人员" :value="1"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item v-if="accountForm.usertype==0" prop="username">
                  <el-input placeholder="手机号/账号" v-model="accountForm.username"></el-input>
                </el-form-item>
                <el-form-item v-if="accountForm.usertype==1" prop="account">
                  <el-input placeholder="账号" v-model="accountForm.account"></el-input>
                </el-form-item>
                <el-form-item prop="password">
                  <el-input type="password" placeholder="密码"
                            v-model="accountForm.password"></el-input>
                </el-form-item>
                <el-form-item>
                  <simp-drag-captcha @unloacked="unloackedPassLogin"></simp-drag-captcha>
                </el-form-item>
                <div class="pad-b10 space-between" style="font-size: 12px;">
                  <el-checkbox v-model="accountForm.rememberMe">两周内自动登录</el-checkbox>
                  <div>
                    <el-link type="info">
                      <router-link :to="'/forgetpassword'">忘记密码</router-link>
                    </el-link>
                  </div>
                </div>
                <el-button type="primary" @click="loginByAccount('accountForm')">登录</el-button>
              </el-form>
            </el-tab-pane>
            <el-tab-pane label="手机验证码登录" name="second" class="no-scroll">
              <el-form autocomplete="off" :model="smsForm" ref="smsForm" :rules="smsFormRules">
                <el-form-item>
                  <el-select style="width: 100%;" v-model="smsForm.usertype" placeholder="账号类型">
                    <el-option label="管理人员" :value="0"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item prop="phone">
                  <el-input placeholder="输入手机号" v-model="smsForm.phone"></el-input>
                </el-form-item>
                <el-form-item prop="code">
                  <el-input placeholder="请输入验证码" v-model="smsForm.code">
                    <el-button @click="captchaConfig.codeTimer.onSendCodeClick('smsForm')"
                               :disabled="captchaConfig.codeTimer.disabled"
                               slot="append">
                      {{ captchaConfig.codeTimer.text }}
                    </el-button>
                  </el-input>
                </el-form-item>
         <!--       <div class="pad-b10 flex flex-sb">
                  <div>
                    <el-checkbox v-model="smsForm.autoRegister">自动注册</el-checkbox>
                  </div>
                </div>-->
                <el-button type="primary" @click="loginBySmsCode('smsForm')">登录</el-button>
              </el-form>
            </el-tab-pane>
          </el-tabs>
          <div class="captcha"></div>
          <div style="font-size: 14px;">
            <div class="qr-code-login-tab">
              <span>扫码登录更方便</span>
              <img alt src="/images/login/qrcode.png"/>
            </div>
          </div>
          <div class="space-between login-bottom">
            <router-link :to="'/register'">注册新账号&gt;</router-link>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="账号注册成功"
      width="500px"
      :close-on-click-modal="false"
      :visible.sync="autoRegisterConfigDialogConfig.show"
      class="auto-register-dialog"
      @close="toIndexPage"
      center>
      <div>
        <el-form :model="autoRegisterConfigDialogConfig.form" ref="autoRegisterForm"
                 autocomplete="off"
                 :rules="autoRegisterConfigDialogConfig.formRules">
          <div class="mg-b15">请确认是否需要修改以下信息：</div>
          <el-form-item label="登录账号" prop="account">
            <el-input placeholder="4—20位字母、数字或下划线组合，不能为纯数字"
                      autocomplete="off"
                      v-model="autoRegisterConfigDialogConfig.form.account">
              <div slot="append">(确认后不可修改)</div>
            </el-input>
          </el-form-item>
          <el-form-item label="登录密码" prop="password">
            <el-input placeholder="6—20位字母、数字、特殊字符组合" type="password"
                      autocomplete="off"
                      v-model="autoRegisterConfigDialogConfig.form.password">
              <div slot="append">(登录后可修改)</div>
            </el-input>
          </el-form-item>
          <el-form-item label="账号类型" prop="usertype">
            <div>
              个人
            </div>
          </el-form-item>
          <el-form-item>
            <el-button @click="autoRegisterConfigDialogConfig.onSubmitClick" type="primary"
                       size="small">保存
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <el-dialog class="captcha-dialog" center :visible.sync="captchaConfig.captcha.show">
      <div class="captcha" ref="captcha"></div>
    </el-dialog>
  </div>
</template>

<script>
  import validator from '@/resources/js/async-validator'
  import {login, getloginCarousels, smslogin} from "@/apis";
  import {sendPhoneCode} from "@/apis/isrsms";

  require('@/resources/libs/jigsaw/jigsaw.min')
  export default {
    name: 'Login',
    components: {
      simpDragCaptcha: () => import('@/components/common/SimpDragCaptcha'),
    },
    data() {
      return {
        loginLock: true,
        loginType: 'first',
        accountForm: {
          usertype: 0,
          username: '',
          account: '',
          password: '',
          rememberMe: false,
        },
        smsForm: {
          phone: '',
          code: '',
          usertype: 0,
          autoRegister: true,
        },
        accountFormRules: {
          username: [{required: true, trigger: ['blur', 'change'], message: '请输入手机号/账号'}],
          account: [{required: true, trigger: ['blur', 'change'], message: '请输入账号'}],
          password: [{required: true, trigger: ['blur', 'change'], message: '请输入密码'}],
        },
        smsFormRules: {
          phone: [
            {required: true, message: '手机号不能为空', trigger: 'blur'},
            {validator: validator.validPhone, message: '手机号格式不正确', trigger: 'blur'}
          ],
          code: [
            {required: true, message: '验证码不能为空', trigger: 'blur'},
            {min: 6, max: 6, message: '密码长度为6位', trigger: 'blur'},
          ]
        },
        marquee: [],
        autoRegisterConfigDialogConfig: {
          show: false,
          form: {
            account: '',
            password: '',
          },
          formRules: {
            account: [
              {required: true, message: '账户不能为空', trigger: ['blur', 'change']},
              {min: 4, max: 20, message: '4—20位字母、数字或下划线组合，不能为纯数字', trigger: ['blur', 'change']},
              {
                required: true,
                trigger: ['blur', 'change'],
                validator: (rule, value, callback) => {
                  let reg = /^(?!\d+$)[\dA-Za-z_]{4,20}$/;
                  reg.test(value)
                    ? callback()
                    : callback(new Error('4—20位字母、数字或下划线组合，不能为纯数字'))
                }
              },
              {
                validator: (rule, value, callback) => {
                  if (!value) {
                    return;
                  }
                  user.checkAccountUniqueness({
                    account: value,
                    userId: (this.user && this.user.userId) || null
                  }).then(res => {
                    const {data} = res;
                    if (data) {
                      callback(new Error('账号已存在'))
                    } else {
                      callback()
                    }
                  }).catch(res => {
                    if (res) {
                      callback(new Error('校验异常'))
                    }
                  })
                }, trigger: ['blur', 'change'],
              }
            ],
            password: [
              {required: true, message: '密码不能为空', trigger: ['blur', 'change']},
              {min: 6, max: 20, message: '密码长度在6~20位之间', trigger: ['blur', 'change']}
            ],
          },
          onSubmitClick: () => {
            this.$refs['autoRegisterForm'].validate()
              .then(() => {
                let {account, password} = this.autoRegisterConfigDialogConfig.form;
                user.saveAuthRegisterUserInfo({
                  userId: this.user.userId,
                  account: account,
                  password: password
                }).then(res => {
                  let {data: user, token} = res;
                  this.$store.commit('account/setUser', user);
                  localStorage.setItem("token", token);
                  this.$message.success('保存成功!');
                  this.toIndexPage();
                })
              })
          }
        },
        captchaConfig: {
          codeTimer: {
            disabled: false,
            time: 60,
            currentTime: 60,
            text: '获取验证码',
            onSendCodeClick: (formName) => {
              this.captchaConfig.codeTimer.sendCode(formName);
            },
            sendCode: (formName) => {
              this.$refs[formName].validateField('phone', valid => {
                if (valid == '') {
                  this.captchaConfig.captcha.initCaptcha()
                    .then(() => {
                      this.captchaConfig.codeTimer.doSendCode();
                    })
                }
              });
            },
            start: () => {
              let {disabled, currentTime} = this.captchaConfig.codeTimer;
              if (disabled) {
                return;
              }
              this.captchaConfig.codeTimer.disabled = true;
              this.captchaConfig.codeTimer.timer = setInterval(() => {
                if (currentTime <= 1) {
                  this.captchaConfig.codeTimer.stop();
                } else {
                  --currentTime;
                  this.captchaConfig.codeTimer.text = `${currentTime}秒后重试`;
                }
              }, 1000);
            },
            stop: () => {
              if (this.captchaConfig.codeTimer.timer) {
                clearInterval(this.captchaConfig.codeTimer.timer);
              }
              this.captchaConfig.codeTimer.currentTime = this.captchaConfig.codeTimer.time;
              this.captchaConfig.codeTimer.text = '重新获取验证码';
              this.captchaConfig.codeTimer.disabled = false;
            },
            doSendCode: () => {
              let {disabled} = this.captchaConfig.codeTimer;
              let phone = this.smsForm.phone;
              if (disabled) {
                return;
              }
              this.captchaConfig.codeTimer.start();
              sendPhoneCode({
                phone: phone
              }).then(res => {
                this.captchaConfig.codeTimer.start();
                this.$message.success('发送验证码成功，请注意查收！');
              }).catch(err => {
                this.captchaConfig.codeTimer.stop();
              });
            },
          },
          captcha: {
            show: false,
            initCaptcha: () => {
              return new Promise(resolve => {
                if (!this.captchaConfig.captcha.show) {
                  this.captchaConfig.captcha.show = true;
                }
                this.$nextTick(() => {
                  this.$refs.captcha.innerHTML = '';
                  window.jigsaw.init({
                    el: this.$refs.captcha,
                    onSuccess: () => {
                      this.$refs.captcha.innerHTML = '';
                      this.captchaConfig.captcha.show = false;
                      resolve();
                    },
                    onFail: () => {
                    },
                    onRefresh: () => {
                    }
                  })
                })
              })
            },
          }
        }
      }
    },
    created() {
      this.getloginCarousels();
    },
    watch: {},
    computed: {},
    mounted() {

    },
    beforeDestroy() {
    },
    methods: {
      toIndexPage() {
        this.$router.push({
          path: '/'
        });
      },
      unloackedPassLogin() {
        this.loginLock = false;
      },
      loginByAccount(formName) {
        this.$refs[formName].validate().then(() => {
          if (this.loginLock) {
            this.$message.warning('请先滑动解锁');
            return;
          }
          let {username: phone, password,account, usertype} = this.accountForm;
          if (usertype==1){
            phone=account;
          }
          login({phone, password, usertype}).then(res => {
            // let {data, token} = res;
            this.$store.commit('account/setUser', res.retdata.userVO);
            localStorage.setItem("token", res.retdata.token);
            this.$Bus.$emit('login');
            this.loginSuccess(usertype);
          })
        })
      },
      loginBySmsCode(formName) {
        this.$refs[formName].validate().then(() => {
          let {phone, code, usertype} = this.smsForm;
          smslogin({phone, code, usertype}).then(res => {
            // let {data, token} = res;
            this.$store.commit('account/setUser', res.retdata.userVO);
            localStorage.setItem("token", res.retdata.token);
            this.$Bus.$emit('login');
            this.loginSuccess(usertype);
          })
        })
      },
      loginSuccess(usertype) {
        let path = {
          0: '/custindex',
          1: '/server'
        }[usertype];
        this.$router.push({
          path: path
        })
      }
      , getloginCarousels() {
        getloginCarousels().then(res => {
          if (res.retdata) {
            this.marquee = res.retdata;
          }
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  .login-form-wrap {
    min-height: 600px;
    display: flex;
    position: relative;

    .marquee {
      position: absolute;
      width: 100%;
      height: 600px;
      z-index: 0;
    }
  }

  .login-form-wrap .login-form {
    width: 350px;
    border-radius: 3px;
    background: #fff;
    padding: 20px 30px;
    position: relative;
  }

  .login-form .el-tabs__content {
    min-height: 300px;
  }

  .login-form .el-form {
    padding-top: 7px;
  }

  .login-form .el-form .el-button {
    display: block;
    width: 100%;
  }

  .qr-code-login-tab {
    font-size: 12px;
    position: absolute;
    top: 5px;
    right: 5px;
  }

  .qr-code-login-tab > span {
    position: relative;
    top: -43px;
    padding: 2px 5px;
    border: 1px solid $main-color;
    border-radius: 3px;
    color: $main-color;
  }

  .qr-code-login-tab > span:after {
    content: '';
    position: absolute;
    border: 1px solid $main-color;
    width: 6px;
    height: 6px;
    right: -4px;
    bottom: 7px;
    transform: rotate(45deg);
    background-color: #FFF;
    border-left-color: #FFF;
    border-bottom-color: #FFF;
  }

  .space-between {
    display: flex;
    justify-content: space-between;
    padding: 5px;
  }

  .login-bottom {
    font-size: 12px;
    background-color: #e8e8ee;
    width: calc(100% + 60px);
    position: relative;
    margin-top: -20px;
    left: -30px;
    right: -30px;
    bottom: -20px;
    padding: 12px 30px;
  }
  .no-scroll{
    overflow: hidden;
    overflow-x: hidden;
  }
</style>
